const secret = {
REACT_APP_API_KEY : "AIzaSyCWiOfB7YhsDt2s0SjTg5jRiG4lc4A4ZbA",
REACT_APP_AUTH_DOMAIN: "portfolio-44dcb.firebaseapp.com",
REACT_APP_DATABASE_URL: "https://portfolio-44dcb-default-rtdb.firebaseio.com",
REACT_APP_PROJECT_ID: "portfolio-44dcb",
REACT_APP_STORAGE_BUCKET:  "portfolio-44dcb.appspot.com",
REACT_APP_MESSAGING_SENDER_ID:  "263098256574",
REACT_APP_APP_ID: "1:263098256574:web:144f765d2e74d042b0e2ef",
REACT_APP_MEASUREMENT_ID:  "G-6KN7DGXDR1"

}

export default secret